import React            from "react";
// import { Link }         from "gatsby";
import styled           from 'styled-components';
import { Header,
         SubHeader,
         Body }         from '../Typography';
import { Colors, Breakpoints } from "../../theme";
import SiteContent      from "../Layouts/SiteContent";
import { ButtonPrimary }                     from '../Buttons';

const ContactWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  min-height: 500px;
  height: 30vh;
  background-color: white;
`;

const ContentBlock = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.16);
	justify-content: center;
  padding: 54px;
  border-radius: 12px;
  text-align: center;

	@media only screen and (max-width: ${Breakpoints.tabletSmall}) {
		padding: 32px;
	}

	@media only screen and (max-width: ${Breakpoints.mobile}) {
		padding: 24px;
	}
`;

const Spacer = styled.div`
  height: 32px;
`;

const ContactSection = () => (
	<ContactWrapper id={'Contact'}>
    <SiteContent center>
    <ContentBlock>
				<SubHeader color={Colors.Commercial}>Contact</SubHeader>
				<Header h3 color={Colors.Primary}>Let's Do This.</Header>
				<Body>All questions are good questions—and we’re here to help. Let us know what you’re trying to accomplish, and we’ll work with you to make it happen.</Body>
        <Spacer/>
        <ButtonPrimary
            marginAuto
            href={'https://clienthub.getjobber.com/client_hubs/c1a25f75-f243-439f-ab61-637e781c1d7e/public/work_request/new?source=social_media'}
            title={'Contact Us'}
            target="_blank"
          />
      </ContentBlock>


    </SiteContent>
	</ContactWrapper>
);

export default ContactSection;