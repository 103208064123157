import React from "react";
import { Link } from "gatsby";
import styled from 'styled-components';
import PropTypes from "prop-types";
import { Colors } from '../theme/Colors';
import ButtonPrimary from './Buttons/ButtonPrimary';
import LogoDark from '../images/svgs/vastech-logo.svg';
import { Breakpoints } from "../theme";

const PositionWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
  background-color: white;
`;

const HeaderWrapper = styled.div`
  margin: 16px 32px;
  display: flex;
  height: 36px;
  justify-content: space-between;
  align-items: center;
  height: 80px;

  @media only screen and (max-width: ${Breakpoints.tablet}) {
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    margin: 16px;
  }
`;

const LogoWrapper = styled.h1`
  flex-direction: start;
  margin: 0;
  display: block;
  width: 340px;
  height: auto;

  @media only screen and (max-width: ${Breakpoints.tablet}) {
    width: 340px;
  }
  @media only screen and (max-width: ${Breakpoints.mobile}) {
    width: 240px;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  margin-top: -4px;

  @media only screen and (max-width: ${Breakpoints.mobile}) {
    margin-bottom: 16px;
  }
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 0 8px;
  background-color: rgba(255, 255, 255, .75);
	backdrop-filter: saturate(210%) blur(6px);
  transition: all 0.6s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
  transition: transform 0.3s ease-in-out;
  // min-width: 230px;

  @media only screen and (max-width: ${Breakpoints.tablet}) {
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    height: 100vh;
    padding: 64px;
    top: 0;
    right: 0;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`;

const Menu = styled.ul`
  list-style-type: none;
  display: inline;
  margin: 0;

  @media only screen and (max-width: ${Breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
  }
`;

const MenuItem = styled.li`
  display: inline;
  padding-left: 12px;
  padding-right: 12px;
  font-family: 'Space Grotesk', sans-serif;
  margin-bottom: 0;

  @media only screen and (max-width: ${Breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    padding: 0;
    a {
      font-size: 32px;
    }
  }
`;

const LinkStyled = styled(Link)`
  border-bottom: 2px solid transparent;
  transition: all 0.6s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
  color: ${Colors.Black};
  font-size: 18px;
  font-weight: 300;

    &:hover {
      border-bottom: 2px solid transparent;
      color: ${Colors.Purple};
    } 
`;

const Spacer = styled.div`
  width: 16px;

  @media only screen and (max-width: ${Breakpoints.tablet}) {
    height: 16px;
  }
`;

const StyledBurger = styled.button`
  position: absolute;
  top: 32px;
  right: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({ open }) => open ? Colors.Pink : Colors.Primary};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }

  @media only screen and (min-width: ${Breakpoints.tablet}) {
    display: none;
}
`;


const Burger = ({ open, setOpen }) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  )
}



const NavBar = ({ siteTitle, light, location }) => {

  const [open, setOpen] = React.useState(false);

  return (
    <PositionWrapper>
      <HeaderWrapper>
        <LogoWrapper>
          <Link to='/'>
            <LogoDark />
            {/* {renderLogo(light)} */}
          </Link>
        </LogoWrapper>

        <HeaderRight open={open} onClick={() => setOpen(!open)}>
          <MenuWrapper>
            <Menu>
              {/* <MenuItem><LinkStyled light={light} to='/'>Home</LinkStyled></MenuItem> */}
              <MenuItem><LinkStyled to='/residential/'>Residential</LinkStyled></MenuItem>
              <MenuItem><LinkStyled to='/commercial/'>Commercial</LinkStyled></MenuItem>
              <MenuItem><LinkStyled to='/about/'>About</LinkStyled></MenuItem>
              <MenuItem><a href="https://clienthub.getjobber.com/client_hubs/c1a25f75-f243-439f-ab61-637e781c1d7e/public/work_request/new?source=social_media" target="_blank" rel="noreferrer" ><LinkStyled>Contact</LinkStyled></a></MenuItem>
            </Menu>
          </MenuWrapper>

          <ButtonPrimary
            inverted
            href={'https://ds.vastechbuffalo.com/'}
            target="_blank"
            title={'Digital Signage Login'}
            color={Colors.Purple}
            textColor={light ? Colors.White : Colors.Purple}
          />
          <Spacer />
          <ButtonPrimary
            href={'https://clienthub.getjobber.com/client_hubs/c1a25f75-f243-439f-ab61-637e781c1d7e/login/new?source=share_login'}
            target="_blank"
            title={'Client Hub'}
          />
        </HeaderRight>
        <Burger open={open} setOpen={setOpen} />
      </HeaderWrapper>
    </PositionWrapper>
  )
};

NavBar.propTypes = {
  siteTitle: PropTypes.string,
}

NavBar.defaultProps = {
  siteTitle: ``,
}

export default NavBar
