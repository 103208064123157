import React from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
// import { SubHeader }	from '../Typography'
import { Colors } from '../../theme/Colors';

import ArrowResidential from '../../images/svgs/arrow-residential.svg';
import ArrowCommercial from '../../images/svgs/arrow-commercial.svg';



const propTypes = {
	color: PropTypes.string,
	color1: PropTypes.string,
	color2: PropTypes.string,
	residential: PropTypes.bool,
},
	defaultProps = {
		color: Colors.Primary,
		color1: Colors.Purple,
		color2: Colors.BabyBlue,
		residential: false,
	}


const ArrowWrapper = styled.div`
	width: 21px;
	height: 7px;
	margin-left: 4px;
	display: flex;
	color: black;
	transition: all 200ms ease;

	svg {
		color: ${Colors.Primary};
	}
`;

const Wrapper = styled.div`
	  font-family: "Space Grotesk", sans-serif;
		font-size: 18px;
		letter-spacing: -5%;
		font-weight: 700;
		color: ${({ color }) => color};
		padding: 0;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: ${({ center }) => center ? 'center' : null};
		transition: all 200ms ease;

		::selection {
		  background: #000;
		  color: white;
		}


	  a {
	  	color: ${({ color }) => color};
		  position: relative;
		  text-decoration: none;
		}
		img {
			margin-left: 4px;			
			transition: all 200ms ease;
		}
		&:hover {
			${ArrowWrapper} {
				margin-left: 8px;
				transform: scale(1.15);
			}
		}
`;

const ExternalLink = styled.a``;



const NewLink = ({ to, external, href, children, color, noPadding, color1, color2, center, noArrow, residential }) => {

	let SwitchLink = external ? ExternalLink : Link;

	return (
		<Wrapper center={center} color={color} color1={color1} color2={color2}>

			<SwitchLink
				href={href}
				external={external}
				color={color}
				to={to}
			>{children}</SwitchLink>
			{!noArrow &&
				<ArrowWrapper>
					{residential ?
						<ArrowResidential />
						: <ArrowCommercial />
					}

				</ArrowWrapper>
			}
		</Wrapper>
	);
}

NewLink.propTypes = propTypes;
NewLink.defaultProps = defaultProps;

export default NewLink;