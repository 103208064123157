import { Link } from "gatsby";
import styled from 'styled-components';
import PropTypes from "prop-types";
import React from "react";
import { Colors } from '../../theme/Colors';

const propTypes = {
	color: PropTypes.string,
},
	defaultProps = {
	}

const ButtonComponent = styled.div`
	border-radius: 8px;
	min-height: 32px;
	min-width: 121px;
	display: flex;
	justify-content: center;
	width: fit-content;
	border: ${({ inverted, disabled, color }) => !inverted ? 'none' : '2px solid ' + _borderColor(inverted, disabled, color)};
	background: ${({ inverted, disabled, color }) => _backgroundColor(inverted, disabled, color)};
	color: ${({ inverted, disabled, textColor }) => _textColor(inverted, disabled, textColor)};
	align-items: center;
	justify-content: center;
	align-self: flex-start;
	padding: 12px 24px;
	text-align: center;
	transition: all 0.6s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
	${({marginAuto}) => marginAuto ? `margin: 0 auto` : null};

	&:hover {
		background-color: ${Colors.Purple};
		color: ${Colors.White};
		cursor: pointer;
	}
`;

const ButtonCopy = styled.span`
	font-size: 18px;
	font-weight: bold;
	letter-spacing: -0.25px;
	line-height: 18px;
	font-family: "Space Grotesk", sans-serif;
	&:hover {
		cursor: pointer;
	}
`;

function _borderColor(inverted, disabled, color) {
	if (disabled || (disabled && inverted)) {
		return '#D3D3D3';
	} else if (color) {
		return color;
	} else {
		return Colors.Purple;
	}
}

function _backgroundColor(inverted, disabled, color) {
	if (inverted || (inverted && disabled)) {
		return 'transparent';
	} else if (color) {
		return color;
	} else {
		return 'linear-gradient(96.23deg, #A719BE -7.07%, #17AFD0 98.74%);';
	}
}

function _textColor(inverted, textColor) {
	if (textColor) {
		return textColor;
	} else if (inverted) {
		return Colors.Purple;
	} else {
		return Colors.White;
	}
}

const StyledLink = styled.a`

`;


/**
A primary button that matches the brand.
*/
const ButtonPrimary = ({ ...props }) => {
let SwitchLink = props.href ? StyledLink : Link;
return (
		<SwitchLink to={props.linkTo} href={props.href} target={props.target} rel={props.target === "_blank" ? 'noreferrer' : props.rel}>
			<ButtonComponent
				marginAuto={props.marginAuto}
				inverted={props.inverted}
				disabled={props.disabled}
				color={props.color}
				textColor={props.textColor}>
				<ButtonCopy>{props.title}</ButtonCopy>
			</ButtonComponent>
		</SwitchLink>
	);
};


ButtonPrimary.propTypes = propTypes;
ButtonPrimary.defaultProps = defaultProps;

export default ButtonPrimary;