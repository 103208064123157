import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Colors } from "../../theme/Colors";

const propTypes = {
    color: PropTypes.string,
  },
  defaultProps = {
    color: Colors.White,
  };

const Wrapper = styled.div``;

const Header = ({
  children,
  color,
  noPadding,
  noMargin,
  fontSize,
  margin,
  h1,
  h2,
  h3,
  h4,
  fontWeight,
  ...props
}) => {
  
  const HeaderH1 = styled.h1`
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : null)};
    letter-spacing: -2px;
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "54px")};
    margin: ${({ margin }) => margin ?? '0 0 8px 0'};
    color: ${({ color }) => (color ? color : Colors.White)};
    padding: ${({ noPadding }) => (noPadding ? 0 : "inherit")};
    white-space: pre-line;

    ::selection {
      background: #000;
      color: white;
      background-color: ${Colors.Purple};
    }

    @media only screen and (max-width: 580px) {
      font-size: 48px;
    }

    @media only screen and (max-width: 400px) {
      font-size: 42px;
    }
  `;

  const HeaderH2 = styled.h2`
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : null)};
    font-size: 72px;
    letter-spacing: -3px;
    margin: ${({ margin }) => margin ?? '0 0 8px 0'};
    color: ${({ color }) => (color ? color : Colors.White)};
    padding: ${({ noPadding }) => (noPadding ? 0 : "inherit")};

    ::selection {
      background: #000;
      color: white;
      background-color: ${Colors.Purple};
    }
    @media only screen and (max-width: 780px) {
      font-size: 52px;
      letter-spacing: -1px;
      line-height: 52px;
    }

    @media only screen and (max-width: 580px) {
      font-size: 42px;
      line-height: 42px;
    }
  `;

  const HeaderH3 = styled.h3`
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : null)};
    font-size: 36px;
    letter-spacing: -1px;
    color: ${({ color }) => (color ? color : Colors.White)};
    margin: ${({ margin }) => margin ?? '0 0 8px 0'};
    padding: ${({ noPadding }) => (noPadding ? 0 : "inherit")};

    ::selection {
      background: #000;
      color: white;
      background-color: ${Colors.Purple};
    }

    @media only screen and (max-width: 780px) {
      font-size: 32px;
      letter-spacing: 0;
    }

    @media only screen and (max-width: 580px) {
      font-size: 28px;
    }
  `;

  const HeaderH4 = styled.h4`
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : null)};
    font-size: 32px;
    letter-spacing: -1px;
    color: ${({ color }) => (color ? color : Colors.White)};
    margin: ${({ margin }) => margin ?? '0 0 8px 0'};
    padding: ${({ noPadding }) => (noPadding ? 0 : "inherit")};

    ::selection {
      background: #000;
      color: white;
      background-color: ${Colors.Purple};
    }

    @media only screen and (max-width: 780px) {
      font-size: 28px;
      letter-spacing: 0;
    }

    @media only screen and (max-width: 580px) {
      font-size: 24px;
    }
  `;

  return (
    <Wrapper color={color}>
      {h1 && (
        <HeaderH1
          {...props}
          color={color}
          fontWeight={fontWeight}
          noMargin={noMargin}
          fontSize={fontSize}
          margin={margin}
        >
          {children}
        </HeaderH1>
      )}
      {h2 && (
        <HeaderH2
          {...props}
          color={color}
          fontWeight={fontWeight}
          noMargin={noMargin}
          fontSize={fontSize}
          margin={margin}
        >
          {children}
        </HeaderH2>
      )}
      {h3 && (
        <HeaderH3
          {...props}
          color={color}
          fontWeight={fontWeight}
          noMargin={noMargin}
          fontSize={fontSize}
          margin={margin}
        >
          {children}
        </HeaderH3>
      )}
      {h4 && (
        <HeaderH4
          {...props}
          color={color}
          fontWeight={fontWeight}
          noMargin={noMargin}
          fontSize={fontSize}
          margin={margin}
        >
          {children}
        </HeaderH4>
      )}
    </Wrapper>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
