import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Colors } from "../../theme/Colors";

const propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.number,
    lineHeight: PropTypes.number,
  },
  defaultProps = {
    color: Colors.Black,
    fontSize: 21,
    lineHeight: 28,
  };

function _lineHeightAdjust(tight, lineHeight) {
  if (tight) {
    return 20;
  } else if (lineHeight) {
    return lineHeight;
  } else {
    return 36;
  }
}

const Wrapper = styled.div`
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : null)}px;
  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? 18 : 0)}px;
	${(align) => align === 'center' && `
		margin: 0 auto;
	`}
`;

const BodyCopy = styled.p`
  font-family: "Space Grotesk", sans-serif;
  font-size: ${({ fontSize }) => fontSize}px;
  opacity: ${({ opacity }) => (opacity ? 1 : 0.8)};
  line-height: ${({ tight, lineHeight }) =>
    _lineHeightAdjust(tight, lineHeight)}px;
  color: ${({ color }) => color};
  font-weight: ${({ bold }) => (bold ? 700 : 100)};
  white-space: pre-line;
  text-align: ${({ align }) => (align ? align : null)};
	${(align) => align === 'center' && `
		margin: 0 auto;
	`}

  ::selection {
    background: #000;
    color: white;
    background-color: ${Colors.Purple};
  }
`;

const Body = ({
  fontSize,
  children,
  color,
  maxWidth,
  bold,
  paddingBottom,
  style,
  opacity,
  tight,
	align,
  lineHeight,
  ...props
}) => {
  return (
    <Wrapper {...props} maxWidth={maxWidth} paddingBottom={paddingBottom} style={style} align={align}>
      <BodyCopy
				align={align}
        fontSize={fontSize}
        opacity={opacity}
        bold={bold}
        color={color}
        tight={tight}
        lineHeight={lineHeight}
      >
        {children}
      </BodyCopy>
    </Wrapper>
  );
};

Body.propTypes = propTypes;
Body.defaultProps = defaultProps;

export default Body;
