import { Link } from "gatsby"
import React from "react"
import styled from 'styled-components';
import PropTypes from "prop-types";
import { Colors, Breakpoints } from '../theme';
import LogoDark from '../images/svgs/vastech-logo.svg';
import { Body } from "./Typography";

const FooterWrapper = styled.div`
  margin-bottom: 1.45rem;
  text-align: center;
`;

const LinkStyled = styled(Link)`
  border-bottom: 2px solid transparent;
  transition: all 0.6s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
  color: ${Colors.Black};
  font-size: 18px;
  font-weight: 300;

    &:hover {
      border-bottom: 2px solid transparent;
      color: ${Colors.Purple};
    } 
`;

const MenuWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
`;

const MenuItem = styled.li`
  display: inline;
  padding-left: 12px;
  padding-right: 12px;
  font-family: 'Space Grotesk', sans-serif;
  margin-bottom: 0;

  @media only screen and (max-width: ${Breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }
`;

const Menu = styled.ul`
  list-style-type: none;
  display: inline;
  margin: 0;

  @media only screen and (max-width: ${Breakpoints.tablet}) {
    display: flex;
  }

  @media only screen and (max-width: ${Breakpoints.tablet}) {
    flex-direction: column;
  }
`;

const LogoWrapper = styled.h1`
  flex-direction: start;
  margin-bottom: 32px;
  display: block;
  width: 340px;
  height: auto;
  @media only screen and (max-width: ${Breakpoints.tablet}) {
    width: 340px;
  }
  @media only screen and (max-width: ${Breakpoints.mobile}) {
    width: 240px;
  }
`;



// const Logo = styled.Link`
//   color: white;
//   text-decoration: none;
// `;

const Footer = ({ siteTitle }) => (
  <FooterWrapper>
    <footer>

      <MenuWrapper>

        <Menu>
          <LogoWrapper>
            <Link to='/'>
              <LogoDark />
              {/* {renderLogo(light)} */}
            </Link>
          </LogoWrapper>
          {/* <MenuItem><LinkStyled light={light} to='/'>Home</LinkStyled></MenuItem> */}
          <MenuItem><LinkStyled to='/residential/'>Residential</LinkStyled></MenuItem>
          <MenuItem><LinkStyled to='/commercial/'>Commercial</LinkStyled></MenuItem>
          <MenuItem><LinkStyled to='/about/'>About</LinkStyled></MenuItem>
          <MenuItem><LinkStyled to='#'>Contact</LinkStyled></MenuItem>
        </Menu>
      </MenuWrapper>
      <a href="tel:7167668324">716-766-TECH (8324)</a>
      <Body fontSize={14}>© {new Date().getFullYear()} VASTech Solutions LLC</Body>
    </footer>
  </FooterWrapper>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer;
