export const Colors = {
  Primary: '#242B63',
  Commercial: '#C42B65',
  Residential	 : '#17AFD0',


Blue: '#1171E1',
Pink: '#C42B65',
OldPurple 	 : '#6700E0',
DeepPurple: '#5922E1',
SuperDarkPurple: '#0A0E2D',
LightPurple: '#7922E1',
Purple 	 : '#8911E1',
DarkPurple: '#14062B',
BabyBlue : '#0092E0',
White 	 : '#FFFFFF',
Black		 : '#000000',
Red			 : '#FF0320',
Orange 	 : '#E08A00',
NewOrange: '#FA8033',
Aqua		 : '#17AFD0',
OffBlack : '#0D1112',
Grey		 : '#686868',
}

export default Colors;